// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";

body {
    background: #fff;
}

.bg-dark {
    background-color: #000 !important;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 1);
    text-transform: uppercase;
    font-weight: bold;
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
    color: red;
}

@media (min-width: 992px) {
    .navbar-nav.nav-left .nav-link {
        padding-left: 1.25rem !important;
        padding-right: 1.25rem !important;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
    font-weight: bold;
}

h1 .btn {
    text-transform: none;
}

.btn-group-xs>.btn,
.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.btn-delete {
    line-height: 1.3;
}

.actions .btn-warning,
.btn-info {
    color: #fff;
}

.actions .btn-xs {
    min-width: 25px;
}

/* forms */

.searchform-inline .form-control {
    width: auto;
    display: inline-block;
}

.searchform-inline .input-4,
.searchform-inline select {
    width: 8em !important;
}

.fundraising-searchform-inline .input-4,
.fundraising-searchform-inline select,
form .width-10 {
    width: 10em !important;
}

.searchform-inline .form-control-sm,
.searchform-inline .btn {
    margin-left: 2px;
    margin-bottom: 5px;
}

.note-group .input-sm,
.address-group .input-sm {
    margin-bottom: 5px;
}

label {
    font-weight: bold;
}

@media (max-width: 575px) {
    label {
        margin-top: 0.5rem;
        margin-bottom: 0;
    }
}

.label-in-line {
    text-align: left;
}

@media (min-width: 576px) {
    .label-in-line {
        text-align: right;
    }
}

.checkboxes label {
    font-weight: normal;
}

.btn-save-in-form {
    margin: -12px 0 15px 0;
}

.information-checked.not-checked {
    background-color: rgba(255, 0, 0, .1);
}

/* forms end */

ul.pagination {
    justify-content: flex-end;
}

.contacts-right-column {
    background-color: #efefef;
}

.menu-divider {
    color: #fff !important;
}

/* forms */
.tel-link {
    white-space: nowrap;
}

.strong {
    font-weight: bold;
}

.formline {
    margin: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ddd;

    .col-form-label {
        font-weight: normal;
    }

    ul {
        padding-left: 18px;
    }
}

.strong .col-form-label {
    font-weight: bold;
}

.strong .select2 {
    font-weight: normal;
}

.formline+.formline-header {
    border-bottom: 0;
}

.formline-header {
    background-color: #ddd;
    font-weight: bold !important;
    padding: 5px 0;
    margin: 0;
}

.formline-checkboxes .form-check-inline {
    display: block;
}

.formline-checkboxes label {
    font-weight: normal;
}

.formline-text {
    border-bottom: none;
    padding-bottom: 0;

    ul {
        margin-bottom: 0
    }
}

.formline-checkboxes-no-separator {
    padding-top: 0;
}

mark {
    background-color: transparent;
    color: red;
}

.sort-arrows {
    font-size: 24px;
    line-height: 24px;
}

#event-form .select2-container {
    width: 100% !important;
}

.eventschedulerow-group .row,
.eventconcertrow-group .row {
    margin-left: -5px;
    margin-right: -5px;

    >div {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.select2 {
    max-width: 100%;
}

.blueimp-control {
    font-size: 0
}

.spielort {
    text-transform: uppercase;
}

.disabled {
    color: #666;
}

.error,
.has-error .help-block {
    color: red;
}

label.error,
.repertoire-row label {
    margin-bottom: 0;
}

label.required-input:after {
    content: ' * ';
    color: #f00;
}

.repertoire-item {
    border-bottom: 1px solid #ddd;
    font-size: 0.8rem;
}

.repertoire-lines {
    border-top: 1px solid #ddd;
}

.repertoire-line {
    border-bottom: 1px solid #ddd;
}

.comment-row {
    background-color: #FFFFCC;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 8px;
}

.contacts-right-column p {
    margin-bottom: 0.5rem;
}

/* repertoire/programme drag-and-drop */
.dragdrop .well {
    padding: 8px 8px 8px 8px;
    border: 0;
    background-color: #e0e0e0;
}

#performance-list {

    .common,
    .solo,
    .compulsory {
        margin-bottom: 5px;
    }
}

.compulsory,
.compulsory .dragdrop-target .well {
    background-color: #ffc098;
}

.common,
.common .dragdrop-target .well {
    background-color: #faf6b5;
}

.solo,
.solo .dragdrop-target .well {
    background-color: #e3fab5;
}

.formline.common,
.formline.solo,
.formline.compulsory {
    padding-bottom: 0;
    font-weight: bold;
}

.dragdrop .well .header {
    color: #000;
    font-size: 14px;
    /*font-weight: 700;*/
    margin-bottom: 6px;
    padding-left: 7px;
}

.dragdrop .well .sortable-list~.header {
    margin-bottom: 0;
}

.formline .dragdrop {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
}

.placeholder {
    background-color: #ff0;
    border: 1px dashed #666;
    height: 30px;
    margin-bottom: 5px
}

.sortable-list {
    background-color: #fff;
    list-style: none;
    margin-bottom: 10px;
    min-height: 80px;
    padding: 5px 5px 40px 5px !important
}

.solo .sortable-list {
    min-height: 200px;
}

.sortable-list:last-child {
    margin-bottom: 0
}

.sortable-item {
    background: #f1f1f1;
    cursor: move;
    display: block;
    margin-bottom: 2px;
    padding: 4px 6px;
    color: #5a5a5a;
    font-weight: normal;
}

.choirname {
    display: none;
}

.sortable-list.common .choirname {
    display: inline;
}

.repertoire-container {
    overflow-y: auto;
    max-height: 100vh;
}

.red {
    color: red;
}

.green {
    color: green;
}

/* repertoire/programme drag-and-drop end */

/* registration-form, applicationform */

.applicationform-header {
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
    margin-bottom: 30px;
}

@media (max-width: 767px) {
    .applicationform h1 {
        font-size: 30px;
    }
}

/* end registration-form, applicationform */

/* fundraising */
select.year-select {
    font-size: 1.41rem;
    font-weight: 700;
}

.fundraising-year-color {
    color: red;
}

/* end fundraising */

/* timetables */
#timetablerows-div {
    .timetablerow-group {
        border-top: 1px solid #ddd;
        margin-top: 2px;
        padding-top: 3px;
    }
}

/* end timetables */


.logosmall {
    max-height: 300px;
    width: auto;
}

.logoxtrasmall {
    max-height: 100px;
    width: auto;
}

.manual-container h3 {
    margin-top: 20px;
    font-size: 20px;
}

.manual-container .manual-row {
    margin-bottom: 10px;
    margin-left: 10px;
}

.accordion-toggle:link,
.accordion-toggle:visited,
.accordion-toggle:hover,
.accordion-toggle:active {
    color: #212529;
    text-decoration: none;
}

.accordion-toggle:before {
    /* symbol for "opening" panels */
    content: "▼";
    color: #2780e3;
    font-size: 16px;
    line-height: 32px;
    margin-right: 5px;
}

.accordion-toggle.collapsed:before {
    /* symbol for "collapsed" panels */
    content: "▶︎";
}

.accordion-toggle:hover:before {
    //color: #999;
}

input::placeholder,
textarea::placeholder {
    color: #ccc !important;
}

.row-muted {
    color: #ccc;

    a:link,
    a:visited {
        color: #ccc;
    }

    select {
        color: #ccc;
    }
}
